// Generated by Framer (838580a)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["zUlz1t1Y4"];

const serializationHash = "framer-elkA3"

const variantClassNames = {zUlz1t1Y4: "framer-v-oy92ez"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zUlz1t1Y4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://t.me/rastfim0v"}><motion.a {...restProps} {...gestureHandlers} background={{alt: "", intrinsicHeight: 200, intrinsicWidth: 200, positionX: "center", positionY: "center"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-oy92ez", className, classNames)} framer-15be4wh`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zUlz1t1Y4"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1yZWd1bGFy", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(156, 156, 156))"}}>rastfimov</motion.p></React.Fragment>} className={"framer-1vujcm6"} fonts={["FS;Satoshi-regular"]} layoutDependency={layoutDependency} layoutId={"iF9ZMOvnk"} style={{"--extracted-r6o4lv": "rgb(156, 156, 156)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-elkA3.framer-15be4wh, .framer-elkA3 .framer-15be4wh { display: block; }", ".framer-elkA3.framer-oy92ez { height: 28px; position: relative; text-decoration: none; width: 85px; }", ".framer-elkA3 .framer-1vujcm6 { flex: none; height: auto; left: 51%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 85
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDUln84Mrf: React.ComponentType<Props> = withCSS(Component, css, "framer-elkA3") as typeof Component;
export default FramerDUln84Mrf;

FramerDUln84Mrf.displayName = "logo";

FramerDUln84Mrf.defaultProps = {height: 28, width: 85};

addFonts(FramerDUln84Mrf, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/TTX2Z3BF3P6Y5BQT3IV2VNOK6FL22KUT/7QYRJOI3JIMYHGY6CH7SOIFRQLZOLNJ6/KFIAZD4RUMEZIYV6FQ3T3GP5PDBDB6JY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})